import React, { useState } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Rankings from './components/pages/Rankings';
import Charts from './components/pages/Charts';
import Players from './components/pages/Players';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTable, faChartSimple } from '@fortawesome/free-solid-svg-icons';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<RankingsWithButton />}></Route>
      </Routes>
    </Router>
  );
}

function RankingsWithButton() {
  const [page, setPage] = useState('rankings');

  const showCharts = () => setPage('charts');
  const showRankings = () => setPage('rankings');
  const showPlayers = () => setPage('players');

  return (
    <div className="page-container">
      <div className="button-container">
        <button1
          onClick={showRankings}
          className={`button ${page === 'rankings' ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faTable} />
        </button1>
        <button2
          onClick={showPlayers}
          className={`button ${page === 'players' ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faUser} />
        </button2>
        <button3
          onClick={showCharts}
          className={`button ${page === 'charts' ? "active" : ""}`}
        >
          <FontAwesomeIcon icon={faChartSimple} />
        </button3>
      </div>
      <div className="content-container">
        {page === 'charts' && <Charts />}
        {page === 'rankings' && <Rankings />}
        {page === 'players' && <Players />}
      </div>
    </div>
  );
}

export default App;
