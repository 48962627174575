import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../App.css";

const Rankings = () => {
  const [rankingsData, setRankingsData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("NA Season 5");
  const [uniqueDates, setUniqueDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [isSpecificDateSelected, setIsSpecificDateSelected] = useState(false);
  const [uniqueClasses, setUniqueClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const API_KEY = "AIzaSyBzIy_EXXCI9B9p7S6sHLgufZ4I0Up1odA";
    const SHEET_ID = "1cHwzJjgRg_Vc471mE9-MDVOKLatOMlCg0DWkYiYzFCs";

    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${selectedSheet}?key=${API_KEY}`;

    axios
      .get(url)
      .then((response) => {
        const data = response.data.values;
        if (data.length > 0) {
          setHeaders(data[0]);
          setRankingsData(data.slice(1));
          const uniqueDates = Array.from(
            new Set(data.slice(1).map((row) => row[0]))
          );
          setUniqueDates(uniqueDates);
          const uniqueClasses = Array.from(
            new Set(data.slice(1).map((row) => row[2]))
          );
          setUniqueClasses(uniqueClasses.sort((a, b) => a.localeCompare(b)));
        }
      })
      .catch((error) => {
        console.error("Error fetching data from Google Sheets:", error);
      });
  }, [selectedSheet]);

  const handleSheetChange = (event) => {
    setSelectedSheet(event.target.value);
    setSelectedDate("");
    setIsSpecificDateSelected(false);
    setSelectedClass("");
    event.target.blur();
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
    setIsSpecificDateSelected(selectedDate !== "");
    event.target.blur();
  };

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    event.target.blur();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = rankingsData.filter(
    (row) =>
      (selectedDate === "" || row[0] === selectedDate) &&
      (selectedClass === "" || row[2] === selectedClass) &&
      (searchTerm === "" ||
        row[4].toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const renderCellContent = (cellValue, columnIndex) => {
    if (selectedSheet === "NA Preseason" && columnIndex === 2) {
      return <img src={`/images/${cellValue}.png`} alt={cellValue} />;
    } else if (columnIndex === 2 || columnIndex === 3) {
      return <img src={`/images/${cellValue}.png`} alt={cellValue} />;
    }
    return cellValue;
  };

  return (
    <div className="rankings-page">
      <div className="select-container">
        <select
          id="sheet-select"
          value={selectedSheet}
          onChange={handleSheetChange}
        >
          <option value="NA Season 5">NA Season 5</option>
          <option value="EU Season 5">EU Season 5</option>
          <option value="ASIA Season 5">ASIA Season 5</option>
          <option value="CN Season 5">CN Season 5</option>
          <option value="KR Season 5">KR Season 5</option>
          <option value="NA Season 4">NA Season 4</option>
          <option value="EU Season 4">EU Season 4</option>
          <option value="ASIA Season 4">ASIA Season 4</option>
          <option value="KR Season 4">KR Season 4</option>
          <option value="NA Season 3">NA Season 3</option>
          <option value="EU Season 3">EU Season 3</option>
          <option value="NA Season 2">NA Season 2</option>
          <option value="NA Season 1">NA Season 1</option>
          <option value="EU Season 1">EU Season 1</option>
          <option value="NA Preseason">NA Preseason</option>
        </select>
        <select
          id="date-select"
          value={selectedDate}
          onChange={handleDateChange}
        >
          <option value="">All Dates</option>
          {uniqueDates.map((date, index) => (
            <option key={index} value={date}>
              {date}
            </option>
          ))}
        </select>
        <select
          id="class-select"
          value={selectedClass}
          onChange={handleClassChange}
        >
          <option value="">All Classes</option>
          {uniqueClasses.map((classs, index) => (
            <option key={index} value={classs}>
              {classs}
            </option>
          ))}
        </select>
        <input
          className="search-container"
          type="text"
          placeholder="Name..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th
                key={index}
                className={
                  isSpecificDateSelected && index === 0
                    ? "hide-first-column"
                    : ""
                }
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {(searchTerm !== "" || selectedClass !== "") &&
          filteredData.length === 0 ? (
            <tr>
              <td colSpan={headers.length} className="no-results">
                No results found.
              </td>
            </tr>
          ) : (
            filteredData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={
                      isSpecificDateSelected && cellIndex === 0
                        ? "hide-first-column"
                        : ""
                    }
                  >
                    {renderCellContent(cell, cellIndex)}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Rankings;
