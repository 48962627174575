import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import "../../App.css";

const Charts = () => {
  const [data, setData] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState("GLOBAL Season 5");
  const [selectedDate, setSelectedDate] = useState("UNSET");
  const [uniqueDates, setUniqueDates] = useState([]);
  const [combineSpecs, setCombineSpecs] = useState(false);

  const handleSheetChange = (event) => {
    setSelectedSheet(event.target.value);
    setSelectedDate("UNSET");
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleCheckbox = (event) => {
    setCombineSpecs(event.target.checked);
  };

  useEffect(() => {
    const apiKey = "AIzaSyBzIy_EXXCI9B9p7S6sHLgufZ4I0Up1odA";
    const sheetId = "1cHwzJjgRg_Vc471mE9-MDVOKLatOMlCg0DWkYiYzFCs";
    const range = `${selectedSheet}!A:D`;

    axios
      .get(
        `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`
      )
      .then((response) => {
        const values = response.data.values;

        if (values && values.length > 1) {
          const allDates = values.slice(1).map((row) => row[0]);
          const uniqueDatesArray = Array.from(new Set(allDates));
          setUniqueDates(uniqueDatesArray);

          if (uniqueDatesArray.length > 0 && selectedDate === "UNSET") {
            setSelectedDate(uniqueDatesArray[0]);
          }

          const filteredData = values
            .slice(1)
            .filter((row) => row[0] === selectedDate || selectedDate === "" || selectedDate === "UNSET");

          const uniqueCombinations = {};
          filteredData.forEach((row) => {
            const combination =
              selectedSheet === "NA Preseason" || combineSpecs
                ? row[2]
                : row[3] + " " + row[2];
            uniqueCombinations[combination] =
              (uniqueCombinations[combination] || 0) + 1;
          });

          const sortedData = Object.keys(uniqueCombinations)
  .map((combination) => {
    const labelParts = combination.split(" ");
    if (labelParts[0].startsWith("Ascension") || labelParts[0] === "Talent") {
      labelParts.shift();
    }
    const newLabel = labelParts.join(" ");
    return {
      label: newLabel,
      count: uniqueCombinations[combination],
      color: getColorForLabel(combination),
    };
  })
  .sort((a, b) => b.count - a.count);


          setData(sortedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [selectedSheet, selectedDate, combineSpecs]);

  const getColorForLabel = (label) => {
    const words = label.split(" ");
    const firstWord = words[0] || "";
    const colorMap = {
      Succession: "#003b4e",
      Awakening: "#4e0014",
      Talent: "#004e13",
      Ascension1: "#004e13",
      Ascension2: "#CBA135",
      Ascension3: "#CBA135",
    };
    return colorMap[firstWord] || "#800060";
  };

  return (
    <div>
      <div className="select-container1">
        <select
          id="sheet-select"
          value={selectedSheet}
          onChange={handleSheetChange}
        >
          <option value="GLOBAL Season 5">GLOBAL Season 5</option>
          <option value="NA Season 5">NA Season 5</option>
          <option value="EU Season 5">EU Season 5</option>
          <option value="ASIA Season 5">ASIA Season 5</option>
          <option value="CN Season 5">CN Season 5</option>
          <option value="KR Season 5">KR Season 5</option>
          <option value="GLOBAL Season 4">GLOBAL Season 4</option>
          <option value="NA Season 4">NA Season 4</option>
          <option value="EU Season 4">EU Season 4</option>
          <option value="ASIA Season 4">ASIA Season 4</option>
          <option value="KR Season 4">KR Season 4</option>
          <option value="NA Season 3">NA Season 3</option>
          <option value="EU Season 3">EU Season 3</option>
          <option value="NA Season 2">NA Season 2</option>
          <option value="NA Season 1">NA Season 1</option>
          <option value="EU Season 1">EU Season 1</option>
          <option value="NA Preseason">NA Preseason</option>
        </select>

        <select
          id="date-select"
          value={selectedDate === "UNSET" ? "" : selectedDate}
          onChange={handleDateChange}
        >
          <option value="">All Dates</option>
          {uniqueDates.map((date) => (
            <option key={date} value={date}>
              {date}
            </option>
          ))}
        </select>
      </div>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="combine-classes"
          checked={combineSpecs}
          onChange={handleCheckbox}
        />
        <label htmlFor="combine-classes">Combine Specs.</label>
      </div>
      <div className="chart-container" style={{ height: "1400px" }}>
        <ResponsiveContainer width="90%" height="100%">
          <BarChart
            layout="vertical"
            data={data}
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
          >
            <XAxis type="number" hide />
            <YAxis
              dataKey="label"
              type="category"
              textAnchor="end"
              width={200}
              axisLine={false}
              tickFormatter={(value, index) => `${value} (${index + 1})`}
            />
            <Tooltip
              content={({ payload, active }) => {
                if (active && payload && payload.length) {
                  const dataEntry = payload[0].payload;
                  return (
                    <div className="custom-tooltip">
                      <p className="tooltip-label">{dataEntry.label}</p>
                      <p className="tooltip-count">{`Count: ${dataEntry.count}`}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Bar dataKey="count">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Charts;
